<template>
  <div>
    <v-card flat class="pa-3 mt-2">
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">REQUEST CASKET REPORT</h4></v-toolbar-title>
      </v-toolbar>
      <v-row>
        <v-col
          md="12"
          cols="12"
        ></v-col>
        <v-col
          md="3"
          cols="12"
        >
          <v-select
            v-model="month_of"
            class="mx-3"
            :items="month_of_items"
            item-value="month_of"
            item-text="month_of"
            label="Month Of"
            required
            @change="monthly_remittances"
            dense
          ></v-select>
        </v-col>
        <v-col
          md="3"
          cols="12"
        >
          <v-select
            v-model="casket_factories"
            class="mx-3"
            :items="casket_factories_items"
            item-value="name"
            item-text="name"
            label="Casket Factories"
            required
            @change="monthly_remittances_by_factories"
            dense
          ></v-select>
        </v-col>
      </v-row>
      <v-card-text>
        <v-simple-table dense>
          <template v-slot:top>
              <span class="headline font-weight-light ">
                                                Print
                                                <v-icon
                                                  class="mr-2"
                                                  color="warning"
                                                  @click="print_data(-1)"
                                                >
                                                {{icons.mdiPrinter}}
                                                </v-icon>
                                            </span>
            </v-toolbar>
          </template>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-center text-uppercase">PO#</th>
              <th class="text-center text-uppercase">PO From</th>
              <th class="text-center text-uppercase">Date</th>
              <th class="text-center text-uppercase">Description</th>
              <th class="text-center text-uppercase">Amount</th>
              <th class="text-center text-uppercase">Status</th>
              <th class="text-center text-uppercase">Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in list_of_request_casket_items" :key="item.id">
              <td>{{ item.id }}</td>
              <td class="text-center">
                {{ item.name }}
              </td>
              <td class="text-center">
                {{ item.date }}
              </td>
              <td>
                <v-simple-table dense v-if="item.report_casket.length>0">
                  <template v-slot:default>
            <thead>
            <tr>
              <th class="text-center text-uppercase">Casket Type</th>
              <th class="text-center text-uppercase">Quantity</th>
              <th class="text-center text-uppercase">Amount</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item2 in item.report_casket" :key="item2.id">
              <td>{{ item2.casket_type }}</td>
              <td class="text-center">
                {{ item2.quantity }}
              </td>
              <td class="text-center">
                {{ formatPrice(item2.amount) }}
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
        </td>
        <td class="text-center">
          {{ formatPrice(item.total)}}
        </td>
        <td class="text-center">
          {{ item.status}}
        </td>
        <td class="text-center">
          <div>
            <v-icon
              v-if="!is_printing"
              class="mr-2"
              color="success"
              @click="print_data(list_of_request_casket_items.map(function(x) {return x.id; }).indexOf(item.id))"
            >
              {{icons.mdiPrinter}}
            </v-icon>
            <v-progress-circular color="info" indeterminate
                                 v-else></v-progress-circular>
          </div>
        </td>
        </tr>
        </tbody>
</template>
</v-simple-table>
</v-card-text>
</v-card>
<snack-bar-dialog :snackbar_flag="this.snackbar" :color="this.snackbar_color"
                  :snackbar_text="this.snackbar_text"/>
</div>
</template>

<script>
  import {mdiAlertOutline, mdiCloudUploadOutline, mdiPrinter} from '@mdi/js'
  import {mapGetters, mapActions} from 'vuex'
  import moment from 'moment'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      is_printing: false,
      month_of: '',
      month_of_items: [],
      casket_factories: '',
      casket_factories_items: [],

      list_of_request_casket_items: [],
      is_deleting: false,
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
          mdiPrinter,
        },
      }
    },
    created() {
      this.get_transaction_month_history({
        branch_id: this.branch_id,
      })
        .then(response => {
          this.month_of_items = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    data() {
      return initialState()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
      ...mapGetters('authentication', ['branch_id', 'month_start', 'month_end', 'user_id', 'branch', 'branch_address', 'branch_contact_no']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('transaction_months_history', ['get_transaction_month_history']),
      ...mapActions('request_casket', ['get_request_casket_report', 'get_request_casket_report_by_factories']),
      monthly_remittances() {
        const data = new FormData()
        data.append('branch_id', this.branch_id)
        data.append('month_of', this.month_of)
        this.get_request_casket_report(data)
          .then(response => {
            this.list_of_request_casket_items = response.data[0].casket_data
            this.casket_factories_items = response.data[0].factories_data
          })
          .catch(error => {
            console.log(error)
          })
      },
      monthly_remittances_by_factories() {
        const data = new FormData()
        data.append('branch_id', this.branch_id)
        data.append('month_of', this.month_of)
        data.append('name', this.casket_factories)
        this.get_request_casket_report_by_factories(data)
          .then(response => {
            this.list_of_request_casket_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      print_data(index2) {
        var imgData = this.company_logo
        var payments_array = []
        var data_array = []
        var data = 'REQUEST CASKET'
        var month = this.month_of
        data_array = [80, 200, 60, 300, 80, 80]
        payments_array.push(
          [
            {text: 'PO#', alignment: 'center', style: 'label'},
            {text: 'Casket Factories', alignment: 'center', style: 'label'},
            {text: 'Date', alignment: 'center', style: 'label'},
            {text: 'Description', alignment: 'center', style: 'label'},
            {text: 'Amount', alignment: 'center', style: 'label'},
            {text: 'Status', alignment: 'center', style: 'label'},
          ]
        )
        if (this.list_of_request_casket_items.length > 0) {
          let total_casket_price = 0
          this.list_of_request_casket_items.forEach(function (item, index, payment) {
            var proceed = false
            if (index2 === -1) {
              proceed = true
            } else {
              if (index2 === index) {
                proceed = true
              }
            }
            if (proceed) {
              var casket_data = []
              total_casket_price += parseFloat(item.total)
              casket_data.push(
                [
                  {text: 'Casket Type', alignment: 'center', style: 'label'},
                  {text: 'Quantity', alignment: 'center', style: 'label'},
                  {text: 'Amount', alignment: 'center', style: 'label'},
                ]
              )
              item.report_casket.forEach(function (item2) {
                casket_data.push(
                  [
                    {text: item2.casket_type, alignment: 'left'},
                    {text: item2.quantity, alignment: 'left'},
                    {
                      text: (item2.amount / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                      alignment: 'left'
                    },
                  ]
                )
              })
              payments_array.push(
                [
                  {text: item.id, alignment: 'left'},
                  {
                    text: item.name,
                    alignment: 'left'
                  },
                  {text: item.date, alignment: 'left'},
                  {
                    style: 'tableExample',
                    color: '#444',
                    table: {
                      widths: [130, 60, 80],
                      body: casket_data,
                    },
                    layout: {
                      fillColor: function (rowIndex) {
                        return (rowIndex === 0) ? '#CCCCCC' : null;
                      },
                    },
                  },
                  {
                    text: (item.total / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                    alignment: 'left'
                  },
                  {text: item.status, alignment: 'left'},
                ]
              )
            }

            if (Object.is(payment.length - 1, index)) {
              // execute last item logic
              payments_array.push(
                [
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {
                    text: 'Total:',
                    alignment: 'right',
                    border: [false, false, false, false],
                  },
                  {
                    text: (total_casket_price / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                    alignment: 'left',
                    border: [false, false, false, false],
                  },
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                ]
              )
            }
          })
          // if (index === -1) {
          //
          // }else{
          //   this.list_of_request_casket_items.forEach(function (item, index, payment) {
          //     var casket_data = []
          //     total_casket_price += parseFloat(item.total)
          //     casket_data.push(
          //       [
          //         {text: 'Casket Type', alignment: 'center', style: 'label'},
          //         {text: 'Quantity', alignment: 'center', style: 'label'},
          //         {text: 'Amount', alignment: 'center', style: 'label'},
          //       ]
          //     )
          //     item.report_casket.forEach(function (item2) {
          //       casket_data.push(
          //         [
          //           {text: item2.casket_type, alignment: 'left'},
          //           {text: item2.quantity, alignment: 'left'},
          //           {
          //             text: (item2.amount / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
          //             alignment: 'left'
          //           },
          //         ]
          //       )
          //     })
          //     payments_array.push(
          //       [
          //         {text: item.id, alignment: 'left'},
          //         {
          //           text: item.name,
          //           alignment: 'left'
          //         },
          //         {text: item.date, alignment: 'left'},
          //         {
          //           style: 'tableExample',
          //           color: '#444',
          //           table: {
          //             widths: [130, 60, 80],
          //             body: casket_data,
          //           },
          //           layout: {
          //             fillColor: function (rowIndex) {
          //               return (rowIndex === 0) ? '#CCCCCC' : null;
          //             },
          //           },
          //         },
          //         {
          //           text: (item.total / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
          //           alignment: 'left'
          //         },
          //         {text: item.status, alignment: 'left'},
          //       ]
          //     )
          //     if (Object.is(payment.length - 1, index)) {
          //       // execute last item logic
          //       payments_array.push(
          //         [
          //           {text: '', alignment: 'left', border: [false, false, false, false]},
          //           {text: '', alignment: 'left', border: [false, false, false, false]},
          //           {text: '', alignment: 'left', border: [false, false, false, false]},
          //           {
          //             text: 'Total:',
          //             alignment: 'right',
          //             border: [false, false, false, false],
          //           },
          //           {
          //             text: (total_casket_price / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
          //             alignment: 'left',
          //             border: [false, false, false, false],
          //           },
          //           {text: '', alignment: 'left', border: [false, false, false, false]},
          //         ]
          //       )
          //     }
          //   })
          // }
        } else {
          payments_array.push(
            [
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
            ]
          )
        }


        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
        }
        var docDefinition = {
          pageSize: {
            width: 612,
            height: 936
          },
          pageOrientation: 'landscape',
          content: [
            {
              columns: [
                {image: imgData, width: 54, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE MEMORIAL CHAPEL CO.',
                    {text: this.branch_address, style: 'subheader'},
                    {
                      text: 'S.E.C No. PG201636035 TEL #: (082)333-1809 CONTACT NO.: ' + this.branch_contact_no,
                      style: 'subheader'
                    },
                  ],
                  style: 'header'
                },
              ]
            },
            {text: data, style: 'title'},
            '============================================================================================================',
            {text: 'Printed as of: ' + moment().format('MMMM D, YYYY'), style: 'printed_label'},
            {text: 'Branch: ' + this.branch, style: 'main_info'},
            {text: 'Month of: ' + month, style: 'main_info'},
            ' ',
            {
              style: 'tableExample',
              color: '#444',
              table: {
                widths: data_array,
                body: payments_array,
              },
              layout: {
                fillColor: function (rowIndex) {
                  return (rowIndex === 0) ? '#CCCCCC' : null;
                },
              },
            },
            {
              stack: [
                {
                  columns: [
                    {text: 'Received By:', style: 'cashier_top_margin'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: '', style: 'cashier_for'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: '_________________________________', style: 'cashier_line'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: 'Cashier', style: 'cashier_placeholder'},
                    {text: ''},
                  ]
                },
              ]
            },
            {
              stack: [
                {
                  columns: [
                    {text: 'Verified By: ', style: 'bm_top_margin'},
                    {text: 'Audited By: ', style: 'au_top_margin'},
                  ]
                },
                {
                  columns: [
                    {text: '', style: 'bm_for'},
                    {text: '', style: 'au_for'},
                  ]
                },
                {
                  columns: [
                    {text: '_________________________________', style: 'bm_line'},
                    {text: '_________________________________', style: 'au_line'},
                  ]
                },
                {
                  columns: [
                    {text: 'Chapel Manager', style: 'bm_placeholder'},
                    {text: 'Asst. Auditor', style: 'au_placeholder'},
                  ]
                },
              ]
            },

          ],
          footer: {
            columns: [
              {
                text: 'Generated with Goodlife Savings & Credit Cooperative System',
                alignment: 'center',
                style: 'tableExample'
              }
            ]
          },
          styles: {
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 20]//[left, top, right, bottom]
            },
            subheader: {
              fontSize: 10
            },
            title: {
              fontSize: 15,
              alignment: 'center',
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0]//[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 4, 0, 0]//[left, top, right, bottom]
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
            },
            sub_info: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
            },
            tableExample: {
              fontSize: 9,
            },

            aa_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            bm_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cs_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            aa_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_placeholder: {
              margin: [15, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_for: {
              margin: [0, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_placeholder: {
              margin: [26, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_placeholder: {
              margin: [5, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            au_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
          },
        }
        pdfMake.createPdf(docDefinition).open();
      },
    },
  }
</script>
